/* ====================DESKTOP NAVBAR STYLES==================== */

/* SET DISPLAY, SIZE, POSITION, BACKGROUND COLOR */
.nav-desktop {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  right: 0;
  width: 13%;
  height: 100%;
  position: fixed;
  background-color: #061f27;
  z-index: 6;
}

/* HOME BUTTON */
.home-link {
  position: relative;
  display: block;
  margin: 5vh 0 0 0;
  padding: 0;
  font-size: 24px;
  z-index: 7;
  transition: 0.3s ease-out all;
}

/* SET SIZE/COLOR ON LOGO */
.home-link svg {
  fill: #d0cdd7;
  width: 100%;
  height: 100%;
}

/* HOME BUTTON/CORNERS YELLOW HOVER */
.home-link:hover {
  color: #f7df1e;
}

/* NAVLINKS CONTAINER - ABOUT, PROJECTS, CONTACT, RESUME BUTTON */
.section-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: fixed;
}

/* NAVLINKS - ABOUT, PROJECTS, CONTACT */
.nav-link {
  margin: 0 0 6vh 0;
  font-size: 24px;
  z-index: 1;
  transition: 0.3s ease-out all;
}

/* NAVLINK HOVERS - ABOUT, PROJECTS, CONTACT */
.nav-link:hover {
  color: #f7df1e;
}

/* GITHUB & LINKEDIN LOGO BUTTONS */
.logo-links {
  width: 100%;
  margin: 0 0 5vh 0;
  z-index: 2;
  position: fixed;
  bottom: 0;
}

/* MARGIN BETWEEN GITHUB & LINKEDIN LOGOS */
.github-logo {
  margin: 0 2vw 0 0;
}

/* HOVER TRANSITION ON LOGOS */
.github-logo svg,
.linkedin-logo svg {
  transition: 0.3s ease-out all;
}

/* LOGO HOVERS */
.github-logo svg:hover,
.linkedin-logo svg:hover {
  fill: #f7df1e;
}

/* NAVBAR RESUME BUTTON */
.resume-btn-nav {
  font-family: "Raleway", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", sans-serif;
  font-size: 20px;
  background-color: #061f27;
  color: #d0cdd7;
  border: solid 2px #d0cdd7;
  margin: 0;
  padding: 5px 15px;
  transition: 0.3s ease-out all;
}

/* NAVBAR RESUME BUTTON HOVER */
.resume-btn-nav:hover {
  color: #f7df1e;
  border: solid 2px #f7df1e;
}

/* ====================MEDIA QUERIES==================== */

@media (max-width: 1250px) {
  /* WIDEN NAV */
  .nav-desktop {
    width: 20%;
  }
}

@media (max-width: 900px) {
  /* HIDE IF WIDTH < 900PX */
  .nav-desktop {
    transform: translateX(110%);
    transition: transform 0.5s ease;
    width: 40%;
  }

  /* DISPLAY WHEN BURGER IS CLICKED */
  .nav-desktop.open {
    transform: translateX(0);
    transition: transform 0.5s ease;
  }

  /* ADD VERTICAL SPACE TO HOME BUTTON B/C X BUTTON */
  .home-link {
    margin: 10vh 0 0 0;
  }
}

@media (max-width: 480px) {
  /* HIDE IF WIDTH < 900PX */
  .nav-desktop {
    /* display: none; */
    transform: translateX(110%);
    transition: transform 0.5s ease;
    width: 60%;
  }
}

@media (max-height: 800px) {
  /* ADD VERTICAL SPACE TO HOME BUTTON B/C X BUTTON */
  .home-link {
    margin: 15vh 0 0 0;
  }
}

@media (max-height: 640px) {
  /* ADD VERTICAL SPACE TO HOME BUTTON B/C X BUTTON */
  .home-link {
    display: none;
  }
}

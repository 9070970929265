.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
}

.contact-container {
  width: 50%;
  height: fit-content;
}

.contact-blurb {
  margin: 0 0 5vh 0;
  font-size: 18px;
  line-height: 1.5;
}

.contact-btn {
  font-family: "Raleway", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", sans-serif;
  font-size: 20px;
  background-color: #0b3948;
  color: #d0cdd7;
  border: solid 2px #d0cdd7;
  padding: 5px 15px;
}

.contact-btn:hover {
  color: #f7df1e;
  border: solid 2px #f7df1e;
  transition: 0.3s ease-out all;
}

/* footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0 0 0;
} */

@media (max-width: 480px) {
  .contact-container {
    width: 75%;
  }
}

/* 
Source for section heading corners:
https://codepen.io/heyvian/pen/xEovGd?editors=1100
*/

/* ==========GLOBAL STYLINGS========== */
/* center content */
.App {
  text-align: center;
}

/* set text color for links, remove link underlines */
a {
  text-decoration: none;
  color: #d0cdd7;
}

/* set cursors on pointers */
button {
  cursor: pointer;
}

/* remove margin on headings/paragraphs */
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

/* set padding between sections &  */
section {
  padding: 5vh 0;
}

/* no padding on home section */
section:first-of-type {
  padding: 0;
  margin: 0 0 5vh 0;
}

/* borders between sections */
.border-line {
  border-bottom: solid 2px #d0cdd7;
  width: 70vw;
  margin: 0 0 7vh 0;
}

/* =====border corners for all section headings===== */
.border-corner-heading {
  position: relative;
  /* display: inline-block; */
  display: flex;
  width: auto;
  margin: 0 15vw 5vh 15vw;
  padding: 15px;
  border: 1px solid #d0cdd7;
  box-sizing: border-box;
}

.border-corner-heading::before,
.border-corner-heading::after {
  content: "";
  position: absolute;
  background-color: #0b3948;
}

.border-corner-heading::before {
  width: calc(100% - 40px);
  height: calc(100% + 2px);
  top: -1px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.border-corner-heading::after {
  height: calc(100% - 40px);
  width: calc(100% + 2px);
  left: -1px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.section-heading {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  z-index: 5;
  font-size: 54px;
}

/* =====MEDIA QUERIES===== */
@media (max-width: 480px) {
  section {
    padding: 2.5vh 0;
    margin: 0;
    box-shadow: none;
    background: none;
  }

  section:last-of-type {
    margin: 0;
  }

  .section-heading {
    font-size: 24px;
  }
}

@media (max-width: 661px) {
  .section-heading {
    font-size: 40px;
  }

  /* .section-heading; */
}

.projects {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
}

.projects-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 65vw;
}

.project {
  height: fit-content;
  width: 400px;
  position: relative;
  overflow: hidden;
  margin: 30px;
}

.project-overlay {
  background: rgba(0, 0, 0, 0.85);
  /* background: rgba(255, 255, 255, 0.8); */
  position: absolute;
  height: 99.5%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.project:hover .project-overlay {
  opacity: 1;
}

.project-img {
  width: 100%;
  height: auto;
}

.project-details {
  /* color: #052430; */
  color: #d0cdd7;
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.project:hover .project-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.fade-in {
  top: 20%;
}

.project-name {
  font-size: 26px;
  margin: 0 0 15px 0;
}

.project-descrip {
  font-size: 18px;
  font-weight: 500;
  padding: 0 10px;
  margin: 0 0 15px 0;
}

.project-stack {
  margin: 0 0 15px 0;
}

.project-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.project-links > a {
  font-family: "Raleway", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", sans-serif;
  font-size: 16px;
  /* background-color: #0b3948; */
  color: #d0cdd7;
  /* color: #052430; */
  border: solid 2px #d0cdd7;
  /* border: solid 2px #052430; */
  padding: 5px 15px;
  margin: 0 0 15px 0;
}

.project-links > a:hover {
  color: #f7df1e;
  border: solid 2px #f7df1e;
  /* background-color: #f7df1e; */
  transition: 0.3s ease-out all;
}

@media (max-width: 480px) {
  .projects-container {
    width: 80%;
  }

  .project {
    width: 100%;
    margin: 20px;
  }
}

/* 
Background image sourced from Unsplash user Georgie Cobbs: 
https://unsplash.com/photos/bKjHgo_Lbpo 
*/

/* ====================HOME STYLES==================== */

/* OVERALL SECTION STYLES */
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  min-height: 85vh;
  background-image: url("../../assets/images/background-georgie-cobbs-unsplash.jpg");
  background-position: center;
  background-size: cover;
}

/* SET CONTAINER FLEX, SIZE, MARGIN */
.intro-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 0 25vh 0;
  max-width: 725px;
}

.intro-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  text-align: start;
  color: #061f27;
}

/* POSITION & SIZE MAIN HEADING */
.intro-heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 2.5vh 0;
  font-size: 64px;
  font-weight: 500;
}

/* POSITION & SIZE SUBHEADING */
.intro-subheading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 30px;
  font-weight: 400;
}

/* ====================MEDIA QUERIES==================== */

@media (max-width: 739px) {
  /* DECREASE CONTAINER WIDTH */
  .intro-text {
    width: 80%;
  }

  /* SHRINK HEADING FONT SIZES */
  .intro-heading {
    font-size: 50px;
  }

  .intro-subheading {
    font-size: 30px;
  }
}

@media (max-width: 480px) {
  /* SHRINK HEADING FONT SIZES, ADD VERTICAL MARGIN */
  .intro-heading {
    margin: 3vh 0;
    font-size: 30px;
  }

  .intro-subheading {
    font-size: 18px;
  }
}

@media (max-height: 825px) {
  /* ADD TRANSPARENT BACKGROUND TO TEXT WHEN COVERING PLANT */
  .intro-text {
    background-color: #d8d7d154;
    padding: 0 20px;
    border-radius: 10px;
  }
}

.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
}

.about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
}

.about-text {
  font-size: 18px;
  line-height: 1.5;
  margin: 0 0 5vh 0;
}

.stack-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 0 5vh 0;
}

.stack-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 7px;
  background-color: #f4f4f9;
  padding: 5px;
  margin: 25px;
}

.stack-item svg {
  width: auto;
  height: 65%;
  margin: 0 0 5px 0;
}

.stack-label {
  color: #2f4550;
  font-size: 14px;
  font-weight: 600;
}

.resume-btn-about {
  font-family: "Raleway", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", sans-serif;
  font-size: 20px;
  background-color: #0b3948;
  color: #d0cdd7;
  border: solid 2px #d0cdd7;
  margin: 0;
  padding: 5px 15px;
}

.resume-btn-about:hover {
  color: #f7df1e;
  border: solid 2px #f7df1e;
  transition: 0.3s ease-out all;
}

@media (max-width: 480px) {
  .about-container {
    width: 75%;
  }

  .stack-item {
    width: 60px;
    height: 60px;
    border-radius: 7px;
    background-color: #f4f4f9;
    padding: 5px;
    margin: 10px;
  }

  .stack-label {
    font-size: 12px;
  }
}

@media (max-width: 980px) {
  .about-container {
    width: 65%;
  }
}
